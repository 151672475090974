import React from "react";
import Link from "next/link";
import styled from "@emotion/styled";
import { useRouter } from "next/router";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import SettingsIcon from "@mui/icons-material/Settings";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import CardMembershipIcon from "@mui/icons-material/CardMembership";
import PaymentIcon from "@mui/icons-material/Payment";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import RedeemIcon from "@mui/icons-material/Redeem";
import SettingsInputCompositeIcon from "@mui/icons-material/SettingsInputComposite";
import DashboardIcon from "@mui/icons-material/Dashboard";
import useUser from "hooks/useUser";
import useWebservice from "hooks/useWebservice";
import useEndpoint from "hooks/useEndpoint";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 30px 0 50px;
`;

const MenuLink = styled.a`
  display: flex;
  align-items: center;
  margin: 6px 0;
  font-size: 18px;
  color: #333;
  text-decoration: none;

  svg {
    margin-right: 14px;
    font-size: 20px;
  }

  &:hover {
    color: #14539a;
  }
`;

const Links = () => {
  const router = useRouter();
  const endpoint = useEndpoint();
  const webservice = useWebservice();

  const { user } = useUser();

  const { redirect } = router.query;

  return (
    <Container>
      <Link
        href={{
          pathname: "/",
          query: redirect ? { redirect } : undefined,
        }}
        passHref
      >
        <MenuLink>
          <SwapHorizIcon /> Mensagens
        </MenuLink>
      </Link>
      <Link
        href={{
          pathname: "/subscriptions",
          query: redirect ? { redirect } : undefined,
        }}
        passHref
      >
        <MenuLink>
          <CardMembershipIcon /> Assinaturas
        </MenuLink>
      </Link>
      <Link
        href={{
          pathname: "/rewards",
          query: redirect ? { redirect } : undefined,
        }}
        passHref
      >
        <MenuLink>
          <RedeemIcon /> Recompensas
        </MenuLink>
      </Link>
      <Link
        href={{
          pathname: "/credit-cards",
          query: redirect ? { redirect } : undefined,
        }}
        passHref
      >
        <MenuLink>
          <PaymentIcon /> Cartões
        </MenuLink>
      </Link>
      <Link
        href={{
          pathname: "/connections",
          query: redirect ? { redirect } : undefined,
        }}
        passHref
      >
        <MenuLink>
          <SettingsInputCompositeIcon /> Conexões
        </MenuLink>
      </Link>
      <Link
        href={{
          pathname: "/sessions",
          query: redirect ? { redirect } : undefined,
        }}
        passHref
      >
        <MenuLink>
          <VpnKeyIcon /> Sessões
        </MenuLink>
      </Link>
      {user?.realm === "User" && (
        <Link
          href={{
            pathname: "/settings",
            query: redirect ? { redirect } : undefined,
          }}
          passHref
        >
          <MenuLink>
            <SettingsIcon /> Minha Conta
          </MenuLink>
        </Link>
      )}
      {user?.realm === "ContentCreator" && (
        <Link href={endpoint.dashboardUrl("/")} passHref>
          <MenuLink>
            <DashboardIcon /> Dashboard
          </MenuLink>
        </Link>
      )}
      <MenuLink href="https://ajuda.livepix.gg">
        <HelpOutlineIcon /> Ajuda
      </MenuLink>
      <MenuLink href={webservice.buildUrl("/auth/logout")}>
        <ExitToAppIcon /> Sair
      </MenuLink>
    </Container>
  );
};

export default Links;
