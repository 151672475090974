import React from "react";
import styled from "@emotion/styled";
import Button from "@mui/material/Button";

const Container = styled.div`
  width: 100%;
  margin-bottom: 15px;
`;

const TitleContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;

  button {
    margin-left: 30px;
  }
`;

const Title = styled.h1`
  margin: 0;

  @media (max-width: 800px) {
    font-size: 26px;
  }
`;

const Subtitle = styled.p`
  margin: 0 0 30px;
  font-size: 22px;
  color: #666;
`;

type Props = {
  title: string;
  subtitle?: string;
  action?: { label: string; onClick: () => void };
};

const Header = ({ title, subtitle, action }: Props) => {
  return (
    <Container>
      <TitleContainer>
        <Title>{title}</Title>
        {action && (
          <Button variant="outlined" onClick={action.onClick}>
            {action.label}
          </Button>
        )}
      </TitleContainer>
      {subtitle && <Subtitle>{subtitle}</Subtitle>}
    </Container>
  );
};

export default Header;
