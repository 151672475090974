import React from "react";
import * as Sentry from "@sentry/nextjs";
import { useRouter } from "next/router";
import useWebservice from "./useWebservice";
import useEndpoint from "./useEndpoint";
import { User, UserProfile } from "../lib/types/core";

export type AuthUser = User & {
  features: string[];
  verification?: string;
  profile?: UserProfile;
};

export type UserHook = {
  user?: AuthUser;
  profile?: UserProfile;
  isLoading: boolean;
};

const useUser = (): UserHook => {
  const router = useRouter();
  const endpoint = useEndpoint();
  const webservice = useWebservice();

  const { data: user, error } = webservice.load<AuthUser>("/auth/me");

  React.useEffect(() => {
    if (user) {
      Sentry.setUser({ id: user.id, username: user.username, segment: user.realm });
    }
  }, [user]);

  if (error?.status === 401) {
    router.replace(`${endpoint.authUrl("/login")}?redirect=${endpoint.appUrl(router.asPath)}`);
    return { isLoading: true };
  }

  return {
    user: user?.username ? user : undefined,
    profile: user?.profile,
    isLoading: !user && !error,
  };
};

export default useUser;
