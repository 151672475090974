import React from "react";
import Drawer from "@mui/material/Drawer";
import Menu from "./Menu";

type Props = {
  open: boolean;
  onClose: () => void;
};

const MobileMenu = ({ open, onClose }: Props) => {
  return (
    <Drawer anchor="left" open={open} onClose={onClose}>
      <Menu />
    </Drawer>
  );
};

export default MobileMenu;
