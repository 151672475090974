import React from "react";
import styled from "@emotion/styled";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDiscord } from "@fortawesome/free-brands-svg-icons";
import IconButton from "@mui/material/IconButton";
import Logo from "components/Layout/Logo";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px 0 0;
`;

const LogoContainer = styled.div`
  width: 100px;
`;

const SocialButtons = styled.div`
  margin-top: 10px;

  a {
    margin-right: 10px;
    color: #999;

    svg {
      font-size: 22px;
    }
  }
`;

const Footer = () => {
  return (
    <Container>
      <LogoContainer>
        <Logo />
      </LogoContainer>
      <SocialButtons>
        <IconButton href="https://twitter.com/livepixgg" target="_blank" size="large">
          <TwitterIcon />
        </IconButton>
        <IconButton href="https://www.instagram.com/livepixgg" target="_blank" size="large">
          <InstagramIcon />
        </IconButton>
        <IconButton href="https://discord.gg/livepixgg" target="_blank" size="large">
          <FontAwesomeIcon size="sm" icon={faDiscord as any} />
        </IconButton>
      </SocialButtons>
    </Container>
  );
};

export default Footer;
