import getConfig from "next/config";

const {
  publicRuntimeConfig: { baseUri, authUri, siteUri, dashboardUri },
} = getConfig();

const appUrl = (path: string) => `${baseUri}${path}`;
const authUrl = (path: string) => `${authUri}${path}`;
const siteUrl = (path: string) => `${siteUri}${path}`;
const dashboardUrl = (path: string) => `${dashboardUri}${path}`;

const useEndpoint = () => {
  return {
    appUrl,
    authUrl,
    siteUrl,
    dashboardUrl,
  };
};

export default useEndpoint;
