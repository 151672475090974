import { Global, css } from "@emotion/react";

const styles = css`
  html,
  body,
  #__next {
    height: 100%;
  }

  * {
    scrollbar-color: rgba(0, 0, 0, 0.1);
    scrollbar-width: thin !important;
  }

  *::-webkit-scrollbar {
    width: 10px;
  }

  *::-webkit-scrollbar-track {
    padding: 3px;
  }

  *::-webkit-scrollbar-thumb {
    opacity: 0;
    background-color: rgba(0, 0, 0, 0.1);
  }
`;

const GlobalStyle = () => <Global styles={styles} />;

export default GlobalStyle;
