import React from "react";
import moment from "moment";
import "moment/locale/pt-br";
import styled from "@emotion/styled";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import AccountLayout from "components/Account";
import Header from "components/Account/Header";
import TransactionItem from "components/Account/TransactionItem";
import Button from "@mui/material/Button";
import { Transaction } from "../lib/types/core";
import useWebservice from "../hooks/useWebservice";

const Container = styled.div`
  display: flex;
  height: 100%;
`;

const Content = styled.div`
  width: 100%;
  height: 100%;

  @media (max-width: 800px) {
    padding: 0 20px;
  }
`;

const DateLabel = styled.p`
  margin: 30px 0 15px;
  font-size: 18px;
  font-weight: bold;
  color: #9c9c9c;
`;

const Empty = styled.div`
  font-size: 20px;
  color: #9c9c9c;
`;

const PaginationContainer = styled.div`
  width: 100%;
  padding: 30px 0;
  display: flex;
  justify-content: space-between;
`;

const isSameDay = (a: Transaction, b: Transaction): boolean => {
  const da = new Date(a.createdAt);
  const db = new Date(b.createdAt);

  return da.getDate() === db.getDate() && da.getMonth() === db.getMonth() && da.getFullYear() === db.getFullYear();
};

const formatDate = (date: Date) => moment(date).locale("pt-br").format("LL");

export default function TransactionsPage() {
  const webservice = useWebservice();

  const [page, setPage] = React.useState<number>(1);

  const { data } = webservice.load<Transaction[]>(`/account/transactions?page=${page}`);

  return (
    <AccountLayout>
      <Container>
        <Content>
          <Header title="Mensagens enviadas" />
          <Backdrop open={!data}>
            <CircularProgress color="primary" />
          </Backdrop>
          {data && data.length === 0 && <Empty>Você ainda não enviou nenhuma mensagem.</Empty>}
          {data && data.length > 0 && (
            <>
              {data.map((transaction: Transaction, index) => (
                <>
                  {(index === 0 || !isSameDay(transaction, data[index - 1])) && (
                    <DateLabel>{formatDate(transaction.createdAt)}</DateLabel>
                  )}
                  <TransactionItem key={transaction.id} transaction={transaction as any} />
                </>
              ))}
              <PaginationContainer>
                <Button disabled={page <= 1} onClick={() => setPage((current) => current - 1)}>
                  Mensagens mais recentes
                </Button>
                <Button disabled={data && data.length < 10} onClick={() => setPage((current) => current + 1)}>
                  Mensagens mais antigas
                </Button>
              </PaginationContainer>
            </>
          )}
        </Content>
      </Container>
    </AccountLayout>
  );
}
