import React from "react";
import { useRouter } from "next/router";
import styled from "@emotion/styled";
import Button from "@mui/material/Button";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

const Container = styled.div`
  margin-bottom: 30px;
`;

const BackButton = () => {
  const router = useRouter();
  const { redirect } = router.query;

  if (!redirect) return null;

  return (
    <Container>
      <Button
        variant="outlined"
        startIcon={<KeyboardBackspaceIcon />}
        onClick={() => router.replace(redirect as string)}
        fullWidth
      >
        Voltar
      </Button>
    </Container>
  );
};

export default BackButton;
