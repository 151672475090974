import axios from "axios";
import useSWR from "swr";
import getConfig from "next/config";

const {
  publicRuntimeConfig: { webserviceUri },
} = getConfig();

const buildUrl = (path: string) => `${webserviceUri}${path}`;

const client = axios.create({
  baseURL: webserviceUri,
  timeout: 5_000,
  withCredentials: true,
});

const useWebservice = () => {
  return {
    buildUrl,
    load: <T>(path: string | null) => useSWR<T>(path ? buildUrl(path) : null),
    get: client.get,
    post: client.post,
    put: client.put,
    patch: client.patch,
    delete: client.delete,
  };
};

export default useWebservice;
