import React, { PropsWithChildren } from "react";
import Head from "next/head";
import Script from "next/script";
import styled from "@emotion/styled";
import useUser from "hooks/useUser";
import AccountMenu from "./AccountMenu";

const Container = styled.div`
  padding: 30px 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;

  @media (max-width: 800px) {
    flex-direction: column;
  }
`;

const Content = styled.div`
  flex: 1;
  max-width: 800px;
  padding: 10px 30px 30px;
  background: white;
  border-radius: 5px;
  border: 1px solid #f1f1f1;

  @media (max-width: 600px) {
    padding: 20px 0;
  }
`;

const Layout: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  return (
    <>
      <Head>
        <title>Minha Conta - LivePix</title>
      </Head>
      <Script
        id="ze-snippet"
        src="https://static.zdassets.com/ekr/snippet.js?key=861f81e5-71d0-4e74-bfb2-d968fc5ffe68"
      />
      <Container>
        <AccountMenu />
        <Content>{children}</Content>
      </Container>
    </>
  );
};

export default Layout;
