var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from "@sentry/nextjs";
import { Replay } from "@sentry/replay";

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
  dsn: SENTRY_DSN,
  environment: process.env.SENTRY_ENVIRONMENT,
  autoSessionTracking: true,
  tracesSampleRate: 0.2,
  replaysSessionSampleRate: 0.2,
  replaysOnErrorSampleRate: 1.0,
  integrations: [
    new Replay({
      stickySession: true,
      inlineImages: true,
      collectFonts: true,
      maskAllText: true,
      blockAllMedia: false,
    }),
  ],
});
