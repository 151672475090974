import { createTheme, adaptV4Theme } from "@mui/material/styles";

const theme = createTheme(
  adaptV4Theme({
    palette: {
      mode: "light",
      primary: {
        main: "#1a2e59",
      },
      secondary: {
        main: "#52abd5",
      },
      background: {
        paper: "#fff",
        default: "#F9F9F9",
      },
    },
    typography: {
      fontFamily: "'Exo 2', sans-serif",
      fontSize: 16,
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1280,
        xl: 1920,
      },
    },
    overrides: {
      MuiButton: {
        contained: {
          borderRadius: "100px",
          boxShadow: "none",
        },
        outlined: {
          borderRadius: "100px",
        },
      },
      MuiTooltip: {
        tooltip: {
          backgroundColor: "#333",
          fontSize: "16px",
        },
        arrow: {
          "&::before": {
            backgroundColor: "#333",
          },
        },
      },
      MuiBackdrop: {
        root: {
          zIndex: 1000,
        },
      },
      MuiTableCell: {
        head: {
          borderColor: "#dbdbdb",
        },
        root: {
          borderBottom: "1px solid #222",
        },
      },
      MuiCard: {
        root: {
          border: "1px solid #f3f3f3",
        },
      },
    },
  })
);

export default theme;
