import React from "react";
import styled from "@emotion/styled";
import Avatar from "@mui/material/Avatar";
import { LinearProgress } from "@mui/material";
import useUser from "hooks/useUser";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 10px 0;
`;

const UserName = styled.p`
  margin: 0 0 0 10px;
  font-weight: bold;
  font-size: 24px;
`;

const User = () => {
  const { user, profile, isLoading } = useUser();

  if (isLoading || !user) return <LinearProgress />;

  return (
    <Container>
      <Avatar src={profile?.avatar || undefined} alt={user.username!} />
      <UserName>{user.username}</UserName>
    </Container>
  );
};

export default User;
