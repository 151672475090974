import React from "react";
import styled from "@emotion/styled";
import MenuIcon from "@mui/icons-material/Menu";
import IconButton from "@mui/material/IconButton";
import MobileMenu from "./MobileMenu";
import Menu from "./Menu";
import Avatar from "@mui/material/Avatar";
import useUser from "hooks/useUser";

const MobileMenuContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;

  @media (min-width: 800px) {
    display: none;
  }
`;

const DesktopMenuContainer = styled.div`
  @media (max-width: 800px) {
    display: none;
  }
`;

const AccountMenu = () => {
  const { user, profile } = useUser();
  const [mobileOpen, setMobileOpen] = React.useState<boolean>(false);

  return (
    <>
      <MobileMenuContainer>
        <MobileMenu open={mobileOpen} onClose={() => setMobileOpen(false)} />
        <IconButton edge="start" aria-label="menu" onClick={() => setMobileOpen(true)} size="large">
          <MenuIcon />
        </IconButton>
        <Avatar src={profile?.avatar || undefined} alt={user?.username || ""} />
      </MobileMenuContainer>
      <DesktopMenuContainer>
        <Menu />
      </DesktopMenuContainer>
    </>
  );
};

export default AccountMenu;
