import React from "react";
import styled from "@emotion/styled";
import User from "./User";
import Links from "./Links";
import Footer from "./Footer";
import BackButton from "./BackButton";

const Container = styled.div`
  width: 300px;
  height: 100%;
  padding: 0 30px 30px;

  @media (max-width: 800px) {
    padding-top: 30px;
  }
`;

const Menu = () => {
  return (
    <Container>
      <BackButton />
      <User />
      <Links />
      <Footer />
    </Container>
  );
};

export default Menu;
