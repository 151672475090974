import React from "react";
import moment from "moment";
import "moment/locale/pt-br";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import ChatIcon from "@mui/icons-material/Chat";
import Money from "helpers/Money";
import { Transaction } from "lib/types/core";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 15px;
  margin-bottom: 10px;
  border-radius: 5px;
  background: white;
  border: 1px solid #f3f3f3;

  &:hover {
    background: rgba(0, 0, 0, 0.03);
  }

  @media (max-width: 800px) {
    align-items: baseline;
    flex-direction: column;
  }
`;

const Section = styled.div<{ width?: string; full?: boolean }>`
  display: flex;
  align-items: center;

  ${(props) =>
    props.full &&
    css`
      flex: 1;
      margin: 0 40px;
    `}

  ${(props) =>
    props.width &&
    css`
      width: ${props.width};
    `}

  > svg {
    font-size: 20px;
    color: #999;
  }

  @media (max-width: 800px) {
    margin: 10px 0;
  }
`;

const SectionContent = styled.div`
  margin-left: 10px;
`;

const PayerName = styled.p`
  margin: 0;
  font-size: 20px;
  font-weight: bold;
  color: #333;
`;

const TransactionDate = styled.p`
  margin: 0;
  font-size: 18px;
  color: #9c9c9c;
`;

const TransactionMessage = styled.p`
  margin: 0;
  font-size: 18px;
  color: #666;
`;

const EmptyMessage = styled.span`
  margin: 0;
  font-size: 18px;
  color: #ccc;
`;

const FlaggedMessage = styled.p`
  margin: 0;
  font-size: 14px;
  color: red;
`;

const TransactionAmount = styled.p`
  margin: 0 20px 0 0;
  font-size: 24px;
  font-weight: bold;
  color: #40db6a;
`;

type Props = {
  transaction: Transaction & { creatorName: string };
};

const formatDate = (date: Date) => moment(date).locale("pt-br").format("HH:mm");

const TransactionItem = ({ transaction }: Props) => {
  return (
    <Container className="transaction-item">
      <Section width="140px">
        <SwapHorizIcon />
        <SectionContent>
          <PayerName>{transaction.creatorName}</PayerName>
          <TransactionDate>{formatDate(transaction.createdAt)}</TransactionDate>
        </SectionContent>
      </Section>
      <Section full>
        <ChatIcon />
        <SectionContent>
          <TransactionMessage>
            {transaction.message}
            {!transaction.message && <EmptyMessage>Mensagem não informada</EmptyMessage>}
          </TransactionMessage>
          {transaction.flagged && <FlaggedMessage>Esta transação foi marcada como imprópria.</FlaggedMessage>}
        </SectionContent>
      </Section>
      <Section>
        <SectionContent>
          <TransactionAmount>{Money.format(transaction.amount, transaction.currency || "BRL")}</TransactionAmount>
        </SectionContent>
      </Section>
    </Container>
  );
};

export default TransactionItem;
