import styled from "@emotion/styled";

const LogoImage = styled.img`
  width: 100%;
  max-width: 200px;
`;

type Props = {
  white?: boolean;
};

const Logo = ({ white }: Props) => {
  return <LogoImage src={white ? "/images/logo-white.svg" : "/images/logo.svg"} alt="LivePix" />;
};

export default Logo;
