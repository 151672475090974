class RequestError extends Error {
  public constructor(public status: number, public data: unknown) {
    super("Failed to fetch data");
  }
}

const fetcher = async (url: string) => {
  const res = await fetch(url, { credentials: "include" });

  if (!res.ok) {
    throw new RequestError(res.status, await res.json());
  }

  return res.json();
};

export default fetcher;
